<template>
  <div class="d-flex flex-fill align-items-center mr-4">
    <b-form-group :label="translations.assignee" class="flex-fill">
      <multiselect
      v-model="filters.assignees"
      :disabled="loading"
      :options="filter_options.assignees"
      :multiple="true"
      :showLabels="false"
      :allow-empty="false"
      label="text"
      track-by="value"
      @select="checkAssigneeInput"/>
    </b-form-group>
    <b-form-group :label="translations.payer" class="flex-fill">
      <multiselect
      v-model="filters.payers"
      :disabled="loading"
      :options="filter_options.payers"
      :multiple="true"
      :showLabels="false"
      :allow-empty="false"
      label="text"
      track-by="value"
      @select="checkPayerInput"/>
    </b-form-group>
    <b-button
      data-testid="search-button"
      variant="primary"
      class="mt-2"
      @click="handleSearch">
      <feather type="search"></feather>
    </b-button>
  </div>
</template>

<script>
import translations from '@/translations';
import Multiselect from 'vue-multiselect';
import { CLAIM_ASSIGNEES } from '@/constants/finance';

export default {
  name: 'ClaimAccountsFilters',
  components: {
    Multiselect,
  },
  data() {
    return {
      translations: translations.finance_operations.claim_accounts.filters,
      loading: false,
      filters: {
        assignees: [],
        payers: [],
      },
      filter_options: {
        assignees: [],
        payers: [],
      },
    };
  },
  async beforeMount() {
    this.loading = true;
    this.formatAssigneeOptions();
    await this.loadPayers();
    this.loading = false;
  },
  methods: {
    formatAssigneeOptions() {
      const claimAssignees = CLAIM_ASSIGNEES.map(claimAssign => ({
        value: claimAssign,
        text: claimAssign,
      }));

      this.filters.assignees = [
        { value: '', text: this.translations.default_assignee_filter },
      ];
      this.filter_options.assignees = [
        { value: '', text: this.translations.default_assignee_filter },
        { value: 'unassigned', text: this.translations.blank_option_assignee_filter },
        ...claimAssignees,
      ];
    },
    checkAssigneeInput(assignee) {
      if (assignee.value === '') {
        this.filters.assignees = [ { value: '', text: this.translations.default_assignee_filter } ];
      } else if (this.filters.assignees[0].value === '') {
        this.filters.assignees.splice(0, 1);
      }
    },
    checkPayerInput(payer) {
      if (payer.value === '') {
        this.filters.payers = [ { value: '', text: this.translations.default_payer_filter } ];
      } else if (this.filters.payers[0].value === '') {
        this.filters.payers.splice(0, 1);
      }
    },
    async loadPayers() {
      try {
        const response = await this.$store.dispatch('Financial/getSystemPayers');
        const payers = response.data?.data?.system_payers || [];

        const payerOptions = payers.map(payer => ({
          value: payer.id,
          text: payer.payer_name,
        }));

        this.filter_options.payers = [
          { value: '', text: this.translations.default_payer_filter },
          ...payerOptions,
        ];

        this.filters.payers = [
          { value: '', text: this.translations.default_payer_filter },
        ];
      } catch (err) {
        const errDetail = err?.response?.data?.error?.detail || err;
        const errMsg = `${this.translations.error_loading_payers}: ${errDetail}`;
        this.$noty.error(errMsg);
      }
    },
    handleSearch() {
      this.loading = true;
      const filtersToSend = {};

      const assignees = [];
      this.filters.assignees.forEach(assignee => {
        if (assignee.value !== '') {
          assignees.push(assignee.value);
        }
      });
      filtersToSend.assignees = assignees;

      const payers = [];
      this.filters.payers.forEach(payer => {
        if (payer.value !== '') {
          payers.push(payer.value);
        }
      });
      filtersToSend.system_payer_ids = payers;

      this.$emit('apply-filters', filtersToSend);
      this.loading = false;
    },
  },
};
</script>
