<template>
  <div>
    <b-modal
        id="bulk-update-modal"
        :title="isForm ? translations.list.bulk_update.form_title : translations.list.bulk_update.preview_title"
        :ok-title="isForm ? translations.list.bulk_update.btn_preview : translations.list.bulk_update.btn_save"
        ok-variant="primary"
        :ok-disabled="saving"
        :cancel-disabled="saving"
        @ok="onModalOK"
        @cancel="onModalCancel">
      <div v-if="isForm">
        <p>{{ selectedItemsCount }} {{ translations.list.bulk_update.form_description }}</p>
        <b-form-group :label="translations.list.bulk_update.assignee">
          <b-input-group>
            <b-input-group-prepend is-text>
              <b-form-checkbox class="mr-n2" v-model="form.updateAssignee" @change="onToggleChange('assignee', ...arguments)"/>
            </b-input-group-prepend>
            <b-form-select
                v-model="form.assignee"
                :disabled="!form.updateAssignee"
                :options="assigneeOptions()"/>
          </b-input-group>
        </b-form-group>

        <b-form-group :label="translations.list.bulk_update.payer_mail">
          <b-input-group>
            <b-input-group-prepend is-text>
              <b-form-checkbox class="mr-n2" v-model="form.updatePayerEmail" @change="onToggleChange('payerEmail', ...arguments)"/>
            </b-input-group-prepend>
            <b-form-tags
                v-model="form.payerEmail"
                tag-variant="info"
                separator=","
                no-add-on-enter
                :tag-validator="tagValidator"
                placeholder=""
                :disabled="!form.updatePayerEmail"/>
          </b-input-group>
        </b-form-group>

        <b-form-group :label="translations.list.bulk_update.csm_email">
          <b-input-group>
            <b-input-group-prepend is-text>
              <b-form-checkbox class="mr-n2" v-model="form.updateCSMEmail" @change="onToggleChange('csmEmail', ...arguments)"/>
            </b-input-group-prepend>
            <b-form-tags
                v-model="form.csmEmail"
                tag-variant="info"
                separator=","
                no-add-on-enter
                :tag-validator="tagValidator"
                placeholder=""
                :disabled="!form.updateCSMEmail"/>
          </b-input-group>
        </b-form-group>

        <div v-if="showNoUpdateError" class="text-danger bold ml-3">
          {{ translations.list.bulk_update.no_field_to_update_error }}
        </div>
      </div>
      <div v-else>
        <p>{{ selectedItemsCount }} {{ translations.list.bulk_update.preview_description }}</p>
        <b-form-group :label="translations.list.bulk_update.assignee"
                      :class="{ 'text-secondary': !form.updateAssignee }">
          <div v-if="!form.updateAssignee">
            {{ translations.list.bulk_update.no_update }}
          </div>
          <div v-else>
            <span v-if="!form.assignee">{{ translations.list.bulk_update.blank }}</span>
            <span v-else>{{ form.assignee }}</span>
          </div>
        </b-form-group>

        <b-form-group :label="translations.list.bulk_update.payer_mail"
                      :class="{ 'text-secondary': !form.updatePayerEmail }">
          <div v-if="!form.updatePayerEmail">
            {{ translations.list.bulk_update.no_update }}
          </div>
          <div v-else>
            <span v-if="!form.payerEmail.length">{{ translations.list.bulk_update.blank }}</span>
            <span v-else>{{ form.payerEmail.join(',') }}</span>
          </div>
        </b-form-group>

        <b-form-group :label="translations.list.bulk_update.csm_email"
                      :class="{ 'text-secondary': !form.updateCSMEmail }">
          <div v-if="!form.updateCSMEmail">
            {{ translations.list.bulk_update.no_update }}
          </div>
          <div v-else>
            <span v-if="!form.csmEmail.length">{{ translations.list.bulk_update.blank }}</span>
            <span v-else>{{ form.csmEmail.join(',') }}</span>
          </div>
        </b-form-group>
      </div>
    </b-modal>
  </div>
</template>

<script>
import translations from '@/translations';
import { CLAIM_ASSIGNEES } from '@/constants/finance';
import { email } from 'vuelidate/lib/validators';
import service from '@/services/finance-service';
import { parseResponseError } from '@/http/parsers/parse_response';

export default {
  name: 'ListBulkUpdateModal',
  components: {},
  props: {
    filters: {
      type: Object,
      required: true,
    },
    selected: {
      type: Object,
      required: true,
    },
    selectedItemsCount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      translations: translations.finance_operations.claim_accounts,
      saving: false,
      isForm: true,
      showNoUpdateError: false,
      form: {
        updateAssignee: false,
        assignee: null,
        updatePayerEmail: false,
        payerEmail: null,
        updateCSMEmail: false,
        csmEmail: null,
      },
    };
  },
  methods: {
    save() {
      this.saving = true;

      const body = {
        ...this.filters,
        items_count: this.selectedItemsCount,
        select_all: this.selected.selectAll,
        selected_ids: this.selected.selectedIds,
      };
      if (this.form.updateAssignee) {
        body.assignee = this.form.assignee || '';
      }
      if (this.form.updatePayerEmail) {
        body.payer_email = this.form.payerEmail?.join(',') || '';
      }
      if (this.form.updateCSMEmail) {
        body.csm_email = this.form.csmEmail?.join(',') || '';
      }

      return service.bulkUpdateClaimAccounts(body).then(() => {
        this.$emit('bulk-updated');
        this.$noty.success(`${this.selectedItemsCount} ${this.translations.list.bulk_update.save_success}`);
        this.saving = false;
        return true;
      }).catch(err => {
        this.$noty.error(`${this.translations.list.bulk_update.save_error}: ${parseResponseError(err)}`);
        this.saving = false;
        return false;
      });
    },
    show() {
      this.resetForm();
      this.isForm = true;
      this.$bvModal.show('bulk-update-modal');
    },
    resetForm() {
      this.form.updateAssignee = false;
      this.form.assignee = null;
      this.form.updatePayerEmail = false;
      this.form.payerEmail = null;
      this.form.updateCSMEmail = false;
      this.form.csmEmail = null;
    },
    assigneeOptions() {
      const ret = [ { value: '', text: '' } ];
      CLAIM_ASSIGNEES.forEach(v => {
        ret.push({ value: v, text: v });
      });

      return ret;
    },
    onToggleChange(field, checked) {
      if (!checked) {
        this.form[field] = null;
      }
      this.showNoUpdateError = false;
    },
    tagValidator(tag) {
      return tag === tag.toLowerCase() && email(tag);
    },
    onModalOK(e) {
      if (this.isForm) {
        e.preventDefault();
        if (!this.form.updateAssignee && !this.form.updatePayerEmail && !this.form.updateCSMEmail) {
          this.showNoUpdateError = true;
          return;
        }
        this.isForm = false;
      } else {
        const success = this.save();
        if (success) {
          this.$bvModal.hide('bulk-update-modal');
        } else {
          e.preventDefault();
        }
      }
    },
    onModalCancel(e) {
      if (!this.isForm) {
        e.preventDefault();
      }
      this.isForm = true;
    },
  },
};
</script>
