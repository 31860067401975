<template>
  <claim-tasks-layout>
    <div class="d-flex justify-content-between align-items-center">
      <filters @apply-filters="applyFilters"/>
      <div class="d-flex flex-fill justify-content-end">
        <b-button
          class="mt-2 text-nowrap"
          :disabled="selectedItemsCount<1"
          variant="secondary"
          @click="showBulkUpdateModal">
          {{ translations.claim_accounts.list.btn_bulk_update }}</b-button>
        <list-bulk-update-modal
          ref="bulk-update-modal"
          :selected-items-count="selectedItemsCount"
          :selected="selected"
          :filters="appliedFilters"
          @bulk-updated="onBulkUpdated"/>
      </div>
    </div>

    <div class="w-100">
      <financial-list
        ref="list"
        v-model="currentPage"
        :header-data="TABLE_HEADERS"
        :events="TABLE_EVENTS"
        :data="list"
        :disabled="loading"
        :items-per-page="itemsPerPage"
        :total="total"
        :no-items-message="translations.claim_accounts.list.empty_table"
        itemRefKey="id"
        selectable
        @page-changed="fetchList"
        @row-details="handleDetails"
        @select-changed="onSelectChange"/>
    </div>
  </claim-tasks-layout>
</template>

<script>
import FinancialList, { associateHeaderDataItem } from '@/components/Financial/FinancialList.vue';
import ClaimTasksLayout from '@/components/FinanceOperations/ClaimTasksLayout.vue';
import { parseResponseError } from '@/http/parsers/parse_response';
import service from '@/services/finance-service';
import translations from '@/translations';
import { formatDate } from '@/helpers/finance';
import Filters from '@/components/FinanceOperations/ClaimAccounts/filters.vue';
import ListBulkUpdateModal from './listBulkUpdateModal.vue';

export default {
  name: 'ClaimAccountsList',
  components: {
    ClaimTasksLayout,
    FinancialList,
    Filters,
    ListBulkUpdateModal,
  },
  created() {
    this.TABLE_HEADERS = [
      associateHeaderDataItem(this.translations.claim_accounts.list.client_name, 'client_name'),
      associateHeaderDataItem(this.translations.claim_accounts.list.payer_name, 'payer_name'),
      associateHeaderDataItem(this.translations.claim_accounts.list.billing_launch_date, 'billing_launch_date'),
      associateHeaderDataItem(this.translations.claim_accounts.list.assignee, 'assignee'),
    ];
    this.TABLE_EVENTS = {
      'row-details': {
        title: this.translations.claim_accounts.list.view_details,
        icon: 'eye',
      },
    };
  },
  data() {
    return {
      translations: translations.finance_operations,
      loading: true,
      list: [],
      itemsPerPage: 10,
      currentPage: 1,
      total: 0,
      appliedFilters: {},
      selected: {
        selectAll: false,
        selectedIds: [],
      },
    };
  },
  computed: {
    selectedItemsCount() {
      if (this.selected.selectAll) {
        return this.total - this.selected.selectedIds.length;
      }
      return this.selected.selectedIds.length;
    },
  },
  async beforeMount() {
    const page = this.$route.query?.page ? Number(this.$route.query.page) : 1;
    this.total = page * this.itemsPerPage;
    await this.fetchList(page);
  },
  methods: {
    async fetchList(page) {
      this.loading = true;
      this.currentPage = page;
      this.setParamsToQuery();

      const offset = (page - 1) * this.itemsPerPage;
      const limit = this.itemsPerPage;

      try {
        const { claim_accounts: claimAccounts, total } = await service.getClaimAccounts({
          ...this.appliedFilters,
          order_by: 'id',
          order_descending: true,
          offset,
          limit,
        });

        this.list = claimAccounts;
        this.list.forEach(item => {
          item.billing_launch_date = formatDate(item.billing_launch_date);
        });
        this.total = total;
      } catch (err) {
        this.$noty.error(`${this.translations.claim_accounts.errors.fetch_list}: ${parseResponseError(err)}`);
      } finally {
        this.loading = false;
      }
    },
    handleDetails(id) {
      this.$router.push(`/finance-operations/claim-accounts/${id}`);
    },
    setParamsToQuery() {
      const q = { page: this.currentPage > 1 ? this.currentPage : undefined };

      const newQuery = new URLSearchParams(q).toString();
      const currentQuery = new URLSearchParams(this.$route.query).toString();

      if (newQuery !== currentQuery) {
        this.$router.replace({
          query: q,
        });
      }
    },
    applyFilters(filters) {
      this.$refs.list.clearSelection();
      this.appliedFilters = filters;

      this.fetchList(1);
    },
    onSelectChange(value) {
      this.selected = value;
    },
    showBulkUpdateModal() {
      this.$refs['bulk-update-modal'].show();
    },
    onBulkUpdated() {
      this.fetchList(this.currentPage); // refresh
    },
  },
};
</script>
